// Map used to render Rich Text HTML
// All HTML tags we want to use are listed here
// If the value of the tag is null, the tag is used as is
// If the value is a function, the content is thrown through there first
// -> For example, Links. We only want these arguments, none else
// More Information is here: https://medium.com/oberonamsterdam/an-elegant-way-to-deal-with-rich-text-fields-in-react-66ff82518318


// Deprecated Solution: Quill doesn't give us the options to do easy linebreaks, so I just remove paragraphs alltogether ._.
// eslint-disable-next-line
const br_formatter = ({ children, ...rest }) => {
    if (children[0].type.name === "br") {
        return <br style={{ content: "", margin: "2em", display: "block", fontSize: "3px" }} /> // Style changes height of br space
    } else {
        return <>{children} <br /></>
    }
}


// formatStringToCamelCase and getStyleObjectFromString taken from:
// https://dev.to/qausim/convert-html-inline-styles-to-a-style-object-for-react-components-2cbi
// Since Quill only gives us CSS strings instead of objects, this conversion needs to happen
const formatStringToCamelCase = str => {
    const splitted = str.split("-");
    if (splitted.length === 1) return splitted[0];
    return (
        splitted[0] +
        splitted
            .slice(1)
            .map(word => word[0].toUpperCase() + word.slice(1))
            .join("")
    );
};

export const getStyleObjectFromString = str => {
    const style = {};
    str.split(";").forEach(el => {
        const [property, value] = el.split(":");
        if (!property) return;

        const formattedProperty = formatStringToCamelCase(property.trim());
        style[formattedProperty] = value.trim();
    });

    return style;
};


const rename_attribute = (old_name, new_name) => {
    // Horrid thing: Quill writes css classes into the argument "class", not "className"
    // This clashes with React, and while it still seems to work for my Firefox, this isn't to be taken lightly.
    // So: the function rename_attribute_callback checks whether the object attribute "class" exists, and writes it into "className" if it finds it
    // Also works for every other name

    const rename_attribute_callback = (element) => {


        if (element[old_name] !== undefined) {
            let new_element = { ...element };
            new_element[new_name] = element[old_name];
            delete new_element[old_name];

            return new_element
        }
        return element;
    }

    return rename_attribute_callback
}

const convert_style = (element) => {
    // Converts the Style String by Quill into an object.
    // TODO: Only works half since the style names are still "like-this" instead of "likeThis"
    // Camelcase Conversion may need to happen as well
    // https://stackoverflow.com/questions/9518956/javascript-convert-css-style-string-into-js-object

    if (element["style"] !== undefined) {

        return { ...element, style: getStyleObjectFromString(element["style"]) };
    }

    return element;
}

const apply_functions_to_element = (Type, list_of_functions) => {
    // Because some Elements may need more than one function to change their content, we need to have a for loop going through a potential list of functions

    const resolve = (element) => {

        if (element.children.length === 1 && element.children[0].type.name === "br") {
            let style = { ...element["style"], margin: "5em", display: "block", fontSize: "3px" };
            return <div aria-hidden="true" style={style}></div>;
        }

        for (let func of list_of_functions) {
            element = func(element);
        }

        let { children, ...rest } = element;
        return <Type {...rest}>{children}</Type>
    }

    return resolve
}

let default_list = [
    rename_attribute("class", "className"),
    convert_style
]

let html_map = {
    p: apply_functions_to_element("p", default_list), // Default Text
    span: apply_functions_to_element("span", default_list),
    h1: apply_functions_to_element("h1", default_list), // Various Headers
    h2: apply_functions_to_element("h2", default_list),
    h3: apply_functions_to_element("h3", default_list),
    h4: apply_functions_to_element("h4", default_list),
    h5: apply_functions_to_element("h5", default_list),
    h6: apply_functions_to_element("strong", default_list),
    strong: apply_functions_to_element("strong", default_list), // Bold
    em: apply_functions_to_element("em", default_list), // Italic
    u: apply_functions_to_element("u", default_list), // underlined
    ol: apply_functions_to_element("ol", default_list), // Numbered List
    ul: apply_functions_to_element("ul", default_list), // Unnumbered List
    li: apply_functions_to_element("li", default_list), // List Entry
    sup: apply_functions_to_element("sup", default_list), // super and subscripts
    sub: apply_functions_to_element("sub", default_list),
    pre: apply_functions_to_element("pre", [...default_list, rename_attribute("spellcheck", "spellCheck")]), // Some nice Code Formatting
    blockquote: apply_functions_to_element("blockquote", default_list),
    br: (props) => <br />, // Break, accept nothing as content (seems to happen, bug ?)
    a: apply_functions_to_element("a", default_list), // Link
}

export default html_map