import React from 'react';
import LanguageSelector from './LanguageSelect';
import { ProgressBar } from './ProgressBar';
import header_image from "../images/logo_unisport_o_mat_755.png";

class HeaderBar extends React.Component {
    /*
    HeaderBar containing LanguageSelector and UniSport Logo
    Not much more happening here.
    */

    static defaultProps = {
        renderProgressBar: true,
    }

    renderProgressBar() {

        if (this.props.renderProgressBar) {
            return (
                <div style={{ display: "flex", width: "70%", margin: "auto" }}>
                    <ProgressBar
                        completed={(this.props.cur_id + 1) / (this.props.data_array.length + 1) * 100}
                        backgroundColor="var(--secondary-progress-bar)"
                        intro={false}
                        progress_animation="0.5s ease-in-out"
                    />
                </div>
            );
        } else {
            return null;
        }
    }

    renderWelcomeImage() {
        if (this.props.theming.header_logo_img !== null) {
            return (
                <img
                    className="unisportLogo" // in LanguageSelect.css
                    src={header_image}
                    alt=""
                />
            );
        } else {
            return null;
        }
    }

    render = () => {

        return (
            <header>

                <div className="header">

                    <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "nowrap" }}>
                        <LanguageSelector {...this.props} />


                        {this.renderWelcomeImage()}

                    </div>
                    {this.renderProgressBar()}


                </div>
            </header>
        )
    }
}

export default HeaderBar;