// https://www.tutorialspoint.com/hexadecimal-color-to-rgb-color-javascript
export const hexToRGB = (hex) => {
    let r = 0, g = 0, b = 0;

    // Slice off the Alpha, if given some
    if (hex.length > 7) {
        hex = hex.slice(0, 7);
    }

    if (hex.length === 4) {
        // handling 3 digit hex
        r = "0x" + hex[1] + hex[1];
        g = "0x" + hex[2] + hex[2];
        b = "0x" + hex[3] + hex[3];
    } else if (hex.length === 7) {
        // handling 6 digit hex
        r = "0x" + hex[1] + hex[2];
        g = "0x" + hex[3] + hex[4];
        b = "0x" + hex[5] + hex[6];
    };

    return {
        red: +r,
        green: +g,
        blue: +b
    };
}


export const RGBtoBrightness = ({ red, green, blue }) => {
    return (0.299 * red + 0.587 * green + 0.114 * blue);
}

export const GetFontColorForBackground = (backgroundColor, threshold = 0.4) => {
    let buttonBrightness = RGBtoBrightness(hexToRGB(backgroundColor));
    return buttonBrightness < (255 * threshold) ? "#fff" : "#000";
}