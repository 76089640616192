import React from "react";
import HtmlMapper from 'react-html-map';

import { QuizPage } from "../utils/BasePage";
import html_mapper from "../utils/html_mapper";
// import translations from "../jsonData/translations.json";

import "../css/SnackPage.css";

const Wissenssnack = props => {

    let id = props.cur_id;
    const data_array = props.data_array;
    const data = data_array[id];

    let title = data["title_" + props.lang];

    let snack_text_width;
    if (data.url === null) {
        snack_text_width = "col-12 col-sm-12 col-md-12 col-lg-12";
    } else {
        snack_text_width = "col-12 col-sm-12 col-md-6 col-lg-6";
    };

    const renderImage = () => {
        // Function to determine whether or not to render image
        if (data.url !== null) {
            return (
                <div className="col-12 col-sm-12 col-md-6">
                    <div style={{ display: "flex", alignContent: "center", justifyContent: "center" }}>

                        <img
                            id="snack-image"
                            src={data.url}
                            alt=""
                        />

                    </div>
                </div>
            )
        } else {
            return null
        }
    }

    return (

        <QuizPage {...props}>

            {/* Row for all Contents of Wissenssnack */}
            <main className="row">

                {/* Variable Sized Container for Text depending on whether image exists or not */}
                <div className={snack_text_width}>

                    {/* Textfield (from App.css) with snack-bubble to get the Speech bubble effect */}
                    <div className="textfield" id="snack-bubble">
                        {title !== null ?
                            <h1 className="wissenssnack-text">
                                {/*  translations[props.lang].snacks.header */}
                                {title}
                            </h1>
                            :
                            null
                        }

                        <div className="ql-editor anti-quill text-box">
                            <HtmlMapper html={data["text_" + props.lang]}>
                                {html_mapper}
                            </HtmlMapper>
                        </div>


                    </div>

                </div>

                {/* Image Rendering with Variable Width */}
                {renderImage()}

            </main>

        </QuizPage>
    );
}


export default Wissenssnack;