import React from "react";

import "../css/ProgressBar.css";

export class ProgressBar extends React.Component {
    // yay for https://ramonak.io/posts/react-progress-bar
    /*
    Structure of ProgressBar:
        The three Inline CSS styles are kept in the state of the ProgressBar
        Two of them include a transition, so they look nice.
        outerStyle:
            The outer div is supposed to plop open when it is first rendered, so the initial outerStyle needs to have a height of 0px
            That height is then immediately changed in componentDidMount to 10px, so that a transition between both values can happen
        innerStyle:
            Depending on the "completed" value (between 0 and 100) given in the props, the width of this prop is rendered
            The first value we get in "completed" is not 0.0%, but n%, so if we want the ProgressBar to still start at 0%,
            the same trick is done as in the outerStyle: The initial value in the style is 0%, and in componentDidMount (and didUpdate),
            the value is changed so that the transition can happen
        innerAccent:
            A static CSS div which gives the progress bar a nice reflective look. (Absolutely not stolen from Duolingo.)
        
        Props are:
            backgroundColor: Colour of the inner Progress Bar
            completed: the current percentage
            intro: whether or not the progressbar should plop open when first rendered
            shadow: whether or not the inner Progress Bar should have a shadow and "glow"
            progress_animation: how the inner Progress Bar should jump from percentage to percentage 
    */

    static defaultProps = {
        backgroundColor: "#ff00ff",
        completed: 0,
        intro: true,
        shadow: false,
        progress_animation: "1s linear",
    };

    constructor(props) {
        super(props)

        let start_height = "0px";
        let start_marginBottom = "0px";
        if (!this.props.intro) {
            start_height = "10px";
            start_marginBottom = "-10px";
        }

        let shadow = "unset";
        if (this.props.shadow) {
            shadow = `0 0 10px ${this.props.backgroundColor}`;
        }

        this.state = {
            outerStyle: {
                height: start_height,
                marginBottom: start_marginBottom,
            },
            innerStyle: {
                backgroundColor: this.props.backgroundColor,
                transition: `width ${this.props.progress_animation}`,
                boxShadow: shadow,
            },
            cur_completed: 0,
        }
    }

    componentWillUnmount() {
        // Clear Timeout to prevent a Memory Leak
        clearTimeout(this.mountTimeout);
    }

    componentDidMount() {

        let stateChange = {
            outerStyle: {
                ...this.state.outerStyle,
                height: "10px",
                marginBottom: "-10px",
            },
            innerStyle: {
                ...this.state.innerStyle,
                width: `${this.props.completed}%`,
            }
        };

        // The StateChange is Wrapped in a Timeout so that it happens async
        // I think that is what allows the ProgressBar to render once with the initial styles,
        // and then immediately update to the expanded style.
        // Technically, componentDidMount is called after the render()-function, but internally in React,
        // it seems that the ProgressBar isn't painted immediately, so a small wait needs to happen 
        this.mountTimeout = setTimeout(() => {
            this.setState(stateChange);
        }, 10);
    }


    componentDidUpdate() {

        // To Prevent an update loop, an update is only done when the "completed" prop has changed
        if (this.state.cur_completed !== this.props.completed) {

            let stateChange = {
                innerStyle: {
                    ...this.state.innerStyle,
                    width: `${this.props.completed}%`,
                },
                cur_completed: this.props.completed
            };

            // Update can happen without a timeout here
            this.setState(stateChange);
        }
    }

    render() {
        return (
            <div
                className="outerBarStyle"
                style={this.state.outerStyle}
                // ARIA Labels to make the progressbar readable to screen readers.
                role="progressbar"
                aria-valuetext={this.props.completed}
                aria-valuemin="0"
                aria-valuemax="100"
            >
                <div className="innerBarStyle" style={this.state.innerStyle}>
                    <div className="innerBarAccent">
                    </div>
                </div>
            </div>
        );
    }
}
