import React from "react";
import HtmlMapper from 'react-html-map';

import { QuizPage } from "../utils/BasePage";
import html_mapper from "../utils/html_mapper";

import { ProgressBar } from "../utils/ProgressBar";
import { SnackbarContext } from "../utils/Snackbar";

import translations from "../jsonData/translations.json";

import "../css/ActivityPage.css";

class Activity extends React.Component {


    // Here, we set the context to the Snackbars Context to access it
    // Since Activity is not a function but a class, we cannot use the Hook
    // (I could make a withSnackbar instead which wraps around Activity, but this works as well)
    static contextType = SnackbarContext;

    constructor(props) {

        super(props);

        this.data = props.data_array[props.cur_id];

        this.state = {
            countdownStarted: false,
            ContinueIsDisabled: true,
            countDown: this.data.activity_timer,
        };

        this.startTimer = this.startTimer.bind(this);
        this.clickNext = this.clickNext.bind(this);
        this.renderProgressBar = this.renderProgressBar.bind(this);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    startTimer() {
        /* Called the Start-Activity button is pressed */

        // Set to True to render Countdown Button instead of Start Button
        this.setState({ countdownStarted: true });

        this.timer = setInterval(() => {
            this.handleTimer();
        }, 1000); //interval is updated every 1000ms, calling handleTimer each time
    }

    handleTimer() {
        /* This function is called every second, counts down */

        this.setState({ countDown: this.state.countDown - 1 });

        if (this.state.countDown <= 0) {
            // If Counted down to 0, save progress in Session Storage, update state bool
            sessionStorage.setItem("activity" + this.props.cur_id, "passed");

            this.setState({ ContinueIsDisabled: false });

            // The Context is of the Snackbar, so we can access the snackbars activate() through this.context
            if (this.props.theming.text.snack_bar_activity_done[this.props.lang] !== null) {
                this.context(
                    {
                        id: "activity_done",
                        text: this.props.theming.text.snack_bar_activity_done[this.props.lang],
                        popup_time: 5,
                        color: this.props.theming.colour["--buttons-primary"],
                        preventFocus: true,
                    }
                );
            }

            // Remove Interval so it doesn't endlessly continue to count
            clearInterval(this.timer);
        }
    }



    renderProgressBar() {
        // Function that calculates progress in percent and returns a Progress Bar
        // Is given to BasePage to render just above the navigation Buttons

        if (!this.state.countdownStarted) {
            return null;
        }

        let percentage = Math.min((1 - ((this.state.countDown - 1) / this.data.activity_timer)) * 100, 100);

        return (
            <div>
                <div style={{ display: "flex" }}>
                    <ProgressBar
                        completed={percentage}
                        backgroundColor="var(--primary-progress-bar)"
                        progress_animation="1s linear"
                        shadow={true}
                    />
                </div>
            </div>
        );
    }

    clickNext() {
        // ClickNext, needed for Skip Button which we have to define ourselves
        this.props.change_pos(this.props.cur_id + 1)
    }

    getStartButtons() {
        // Decides to either render only startbutton if Activity hasn't been done, or an optional Skip button as well
        // Skip Button position over the Start Button is hardcoded in CSS

        // Start Button: The same way either way, whether the skip button exists or not
        let startButton = (
            <button
                className="btn btn-secondary"
                type="button"
                id="nextButton"
                onClick={this.startTimer}
            >
                {translations[this.props.lang].buttons.activity_start}
            </button>
        )

        if (sessionStorage.getItem("activity" + this.props.cur_id) !== null) {
            // Optional Skip Button
            return (
                <div className="start-button-container">
                    <button
                        className="btn btn-secondary"
                        type="button"
                        id="skipButton"
                        onClick={this.clickNext}
                    >
                        {translations[this.props.lang].buttons.activity_skip}
                    </button>
                    {startButton}
                </div>
            )
        }

        return startButton;
    }

    renderImage() {
        /* Function that decides whether or not to render image field depending on whether an image is given */

        if (this.data.url === null) {
            return null
        } else {
            return (
                // Takes whole screen when on small or extra small screens, else half of it
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    {/* Properly set in the center */}
                    <div style={{ display: "flex", alignContent: "center", justifyContent: "center" }}>

                        <img
                            src={this.data.url}
                            id="activity-image"
                            alt=""
                        />

                    </div>
                </div>
            )
        }
    }

    render() {

        // Button Deciding: If the countdown hasn't started, show Start (+ optional skip). While counting down, show Numbered Button.
        // If Both are done, nextButton is undefined and the default next button will be rendered
        let nextButton;
        if (!this.state.countdownStarted) {
            nextButton = this.getStartButtons()
        } else if (this.state.ContinueIsDisabled) {
            nextButton = (
                <button
                    className="btn btn-secondary"
                    style={{ cursor: "wait", whiteSpace: "nowrap" }}
                    disabled={true}
                    type="button"
                    id="nextButton"
                >
                    {this.state.countDown}
                </button>
            );
        }

        let title = this.data["title_" + this.props.lang];

        // Decides Width of Text, depending on whether an image exists or not
        let activity_text_width;
        if (this.data.url === null) {
            activity_text_width = "col-12 col-sm-12 col-md-12 col-lg-12 text-box";
        } else {
            activity_text_width = "col-12 col-sm-12 col-md-6 col-lg-6 text-box";
        };

        return (
            // Row organizing the contents
            <QuizPage
                {...this.props}
                nextButton={nextButton}
                bottomComponent={this.renderProgressBar}
            >
                <main className="row">

                    {/* Image Render, including Row Sizes in ClassNames */}
                    {this.renderImage()}

                    {/* Row Sizes for Activity depending on whether Image exists */}
                    <div className={activity_text_width}>

                        {/* Rounded Textbox for Activities */}
                        <div className="textfield">

                            {title !== null ?
                                <h1 className="activity-text">
                                    {/* {translations[this.props.lang].activities.header} */}
                                    {title}
                                </h1>
                                :
                                null
                            }

                            <div className="ql-editor anti-quill">
                                <HtmlMapper html={this.data["text_" + this.props.lang]}>
                                    {html_mapper}
                                </HtmlMapper>
                            </div>

                        </div>

                    </div>

                </main>
            </QuizPage>
        );
    }
}

export default Activity;