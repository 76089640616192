import React, { createContext, useContext } from "react"
import { GetFontColorForBackground } from "./colours";

export const SnackbarContext = createContext(null)

export const useSnackbar = () => {
    // Simple Hook to use the Snackbar Context
    return useContext(SnackbarContext);
}

class SnackbarContainer extends React.Component {
    // Nice small Snackbar. Is a Provider to be wrapped around the whole app
    // Provides one activate function, which takes relevant arguments and displays the Snackbar for a given period
    // The Snackbar doesn't disappear afterwards technically, but is offscreen and transparent
    //   However, when offscreen, the text is not rendered anymore, to combat screenreaders reading wrong text

    constructor(props) {
        super(props);

        this.state = {
            id: null,
            active: false,
            text: "Snackbar!",
            color: "#555555",
            popup_time: 3,
            animation_done: true,
        };

        this.focusElement = React.createRef();

        this.activate = this.activate.bind(this);
        this.deactivate = this.deactivate.bind(this);
        this.deactivateAfterAnimation = this.deactivateAfterAnimation.bind(this);
    }

    componentWillUnmount() {
        clearTimeout(this.replaceTimeout);
        clearTimeout(this.timeout);
        clearTimeout(this.animation_timeout);
    }

    resetState() {
        this.setState({
            id: null,
            text: "Snackbar!",
            color: "#555555",
            popup_time: 3,
            animation_done: true,
        });
    }

    activate(properties) {
        // Activate Function. First, sets every variable to its default values, then writes the properties which were given

        // If no text is given, just skip doing anything.
        if (properties.text === "" || properties.text === null) {
            return
        }

        // If another SnackBar is already open, deactivate it and call the activation function again in 0.2 seconds
        // when the animation from the previous snackbar is done 
        if (this.state.active && this.state.id !== properties.id) {
            this.deactivate();
            this.replaceTimeout = setTimeout(this.activate, 200, properties);
            return;
        }

        // If the Snackbar is already active, just do nothing. This prevents double timeouts
        if (!this.state.active) {
            this.resetState();
            this.setState({ active: true, animation_done: false });

            if (properties !== undefined) {
                this.setState({ ...properties });
            }

            // Timeout: After the relevant time, deactivate is called automatically
            this.timeout = setTimeout(this.deactivate, this.state.popup_time * 1000);
        }


        // Copied from https://stackoverflow.com/questions/53528695/react-set-accessibility-focus-to-an-element
        // Used to set focus to the Snackbar for Accessibility reasons.
        if (!properties.preventFocus) {
            this.focusElement.current.focus();
        }
    }

    deactivate() {

        // If the Snackbar was deactivated manually before the timeout, remove that timeout here
        if (this.timeout !== undefined) {
            clearTimeout(this.timeout);
        }

        // After the deactivation, wait another 200ms until the snackbar dissappeared from the screen to remove the text from it
        this.animation_timeout = setTimeout(this.deactivateAfterAnimation, 200);

        // Resets Snackbar to be inactive again
        this.setState({
            active: false,
        });
    }

    deactivateAfterAnimation() {
        this.setState({ animation_done: true });
        clearTimeout(this.animation_timeout)
    }

    renderSnackbar() {

        let bottom_height = "8rem";
        let opacity = 0;
        let tabFocus = "-1";

        // if Active, move the Snackbar up and make it opaque
        if (this.state.active) {
            bottom_height = "0rem";
            opacity = 1;
            tabFocus = "1";
        }


        // Inline Style instead of CSS to be able to change the values and get nice animations
        let snackbar_style = {
            cursor: "pointer",
            position: "fixed",
            maxWidth: "20rem",
            minHeight: "3rem",
            bottom: "5rem",
            transform: "translateY(" + bottom_height + ")",
            backgroundColor: this.state.color,
            // Calculate font colour from given backgroundColor:
            // If the backgroundColor is bright, use a black font, otherwise white.
            color: GetFontColorForBackground(this.state.color),
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            boxShadow: "0px 0px 10px " + this.state.color,
            opacity: opacity,
            transition: "opacity 0.2s ease-in-out, transform 0.2s ease-in-out, display 0s",
        }

        return (
            <div style={{ display: "flex", justifyContent: "center" }}>

                <button className="noButton" style={snackbar_style} onClick={this.deactivate} role="tooltip" tabIndex={tabFocus} ref={this.focusElement}>
                    <div style={{ textAlign: "center", margin: "0.5rem 2rem 0.5rem 2rem" }}>
                        {/* After the Sliding-Out-Of-The-Screen is done, the text ist replaced by an empty string.
                            This prevents Screen Readers from picking up on the default text of the snackbar,
                            while still allowing the inline CSS to be active and rendered (which needs to be the case
                            so that the transition animations have a correct starting off point.)
                        */}
                        {!this.state.animation_done ? this.state.text : ""}
                    </div>
                </button>

            </div>
        )
    }


    render() {
        // Takes the rest of the app, and renders it with a Provider around and the Snackbar below it
        return (
            <SnackbarContext.Provider value={this.activate}>
                {this.props.children}

                {this.renderSnackbar()}
            </SnackbarContext.Provider>
        )
    }
}

export default SnackbarContainer