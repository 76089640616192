import React from "react";

import WelcomeBasePage from "../utils/WelcomeBasePage";
import { useSnackbar } from "../utils/Snackbar";

import HtmlMapper from 'react-html-map';
import html_mapper from "../utils/html_mapper";
import translations from "../jsonData/translations.json";

import "../css/WelcomePage.css"
import "react-quill/dist/quill.snow.css";

const Welcome = (props) => {

    const snackbar = useSnackbar();

    const clickNext = () => {
        // Function for Go!-Button
        if (props.error === null) {
            props.change_pos(props.cur_id + 1)
        } else {
            snackbar({
                id: "welcome_error",
                color: "#ff5555",
                popup_time: 10,
                text: `Quiz konnte nicht geladen werden, bitte versuche es später noch einmal! Fehler: ${props.error}`
            });
        }
    };

    const renderWelcomeImage = () => {
        if (props.theming.welcome_img !== null) {
            return <img className="center mx-auto" src={props.theming.image.welcome_img} alt="" />;
        } else {
            return null;
        }
    }


    return (
        // WelcomeBasePage handles Background Image, Half Transparent Container, and HeaderBar with LanguageSelect and Small Logo
        <WelcomeBasePage
            {...props}
        >
            {/* Big Logo in Middle */}
            {renderWelcomeImage()}

            <main aria-label="Einführung">

                {/* Welcome Text */}
                {/* To display quill richtext, we need to give the class names https://stackoverflow.com/a/62959457 */}
                <div className="ql-editor anti-quill text-in-center">
                    <HtmlMapper
                        html={props.greeting_texts.pre[props.lang]}
                    >
                        {html_mapper}
                    </HtmlMapper>
                </div>

                {/* Go-Button */}
                <nav id="welcomeButton" style={{ display: "flex", justifyContent: "center", marginBottom: "3rem" }}>
                    <button
                        id="nextButton"
                        className="btn btn-success w-50"
                        type="button"
                        onClick={clickNext}
                    >
                        {translations[props.lang].buttons.start}
                    </button>
                </nav>

                {/* Welcome Text */}
                <div className="ql-editor anti-quill text-in-center">
                    <HtmlMapper
                        html={props.greeting_texts.post[props.lang]}
                    >
                        {html_mapper}
                    </HtmlMapper>
                </div>

                <div className="text-in-center" style={{ fontSize: "13px", textAlign: "center", opacity: "100%" }}>
                    {
                        props.lang === "de" ? (
                            <>
                                Der UniSport-O-Mat wurde vom <a target="_blank" rel="noreferrer" href="https://www.hochschulsport.fu-berlin.de/index.html">Hochschulsport der Freien Universität</a> entwickelt und ist im Rahmen des Projektes "Bewegt studieren - Studieren bewegt! 2.0" mit Unterstützung der <a target="_blank" rel="noreferrer" href="https://www.tk.de/">Techniker Krankenkasse</a> und des <a target="_blank" rel="noreferrer" href="https://www.adh.de/">Allgemeinen Deutschen Hochschulsportverbandes</a> entstanden.
                            </>
                        ) : (
                            <>
                                The UniSport-O-Mat was developed by the <a target="_blank" rel="noreferrer" href="https://www.hochschulsport.fu-berlin.de/index.html">Hochschulsport der Freien Universität</a> and is part of the project "Bewegt studieren - Studieren bewegt! 2.0" with the support of the <a target="_blank" rel="noreferrer" href="https://www.tk.de/">Techniker Krankenkasse</a> and the <a target="_blank" rel="noreferrer" href="https://www.adh.de/">Allgemeinen Deutschen Hochschulsportverbandes</a>.
                            </>
                        )
                    }
                </div>

            </main>
        </WelcomeBasePage>
    );
}

export default Welcome;
