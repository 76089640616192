import React from 'react';

import "../css/FooterBar.css";

const FooterBar = (props) => {
    /*
    FooterBar containing Logos of Collaborators and Impressumg
    Not much more happening here.
    */

    const FooterEntries = () => {

        let entries = props.footer.map((footer_entry, index) => {
            return <FooterEntry entry={footer_entry} key={index} />
        })

        return (
            <footer title="Partner">
                <div className="footer-bar">
                    {entries}
                </div>
            </footer>
        )
    }

    const FooterEntry = (entry) => {

        if (entry.entry.type === "image") {

            return (
                <div className="footer-image">
                    <a href={entry.entry.footer_url} target="_blank" rel="noreferrer">
                        <img src={entry.entry.footer_image_url} alt="Footer">

                        </img>
                    </a>
                </div>
            )
        } else {
            return (
                <div className="footer-text">
                    <a className="footer-a" href={entry.entry.footer_url} target="_blank" rel="noreferrer">
                        {entry.entry.footer_text}
                    </a>
                </div>
            )
        }
    }

    return (
        //header -> padding top 0.5 rem
        <div className="header">

            <FooterEntries />

        </div>
    )
}

export default FooterBar;