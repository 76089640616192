export const sanitize_saved_answer = (key, id, max_questions) => {
    /**
     * Gets the value of an answer and checks whether it is a valid number or has been meddled with
     * If it isn't valid, returns null. Relevant for QuestionPage, where you can only continue if the answer is valid
     */

    let value = parseInt(sessionStorage.getItem(key + (id)));

    if (value < 0 ||
        value === null ||
        value % 1 !== 0
    ) {
        return null;
    }

    if (value >= max_questions) {
        return null;
    }

    if (isNaN(value)) {
        value = 0;
    }

    return value;
}