import React from 'react';

import { QuizPage } from "../utils/BasePage";
import { useSnackbar } from "../utils/Snackbar";
import { sanitize_saved_answer } from "../utils/answer_sanitizer";

import translations from "../jsonData/translations.json";

import LikertInputProvider from '../utils/LikertInputProvider';

import "../css/QuestionPage.css";

const Question = (props) => {

    // Define needed Variables
    let id = props.cur_id;
    const data_array = props.data_array;
    const data = data_array[id];

    const snackbar = useSnackbar();


    // Count which Question we are currently on
    // We can't use ID for that, since ID also counts Snacks and Activities, so we need to do that manually
    let all_question_amount = data_array.filter(entry => entry.type === "question").length;
    // Take Data_arrays data up to and including id, filter out every non-question-entry, length is amount of questions
    let current_question_number = data_array.slice(0, id + 1).filter(entry => entry.type === "question").length;


    const clickNext = () => {
        /* Function for the Next button. Checks whether continuing is allowed */

        let potential_question_answer = sanitize_saved_answer("question", id, props.config["question"].length);
        let potential_relevance_answer = sanitize_saved_answer("relevance", id, props.config["relevance"].length);

        let allow_position_change = potential_question_answer !== null;

        if (props.config.relevance_state === "at_question") {
            allow_position_change = allow_position_change && potential_relevance_answer !== null;
        }

        //Accesses sessionStorage to see whether answers exist (and whether they are valid and sanitized or not)
        if (allow_position_change) {
            //If so, position can be changed
            props.change_pos(id + 1);
            if (all_question_amount - current_question_number === 2) {
                snackbar({ id: "progress_info", text: props.theming.text.progress_info[props.lang], color: props.theming.colour["--secondary-progress-bar"] })
            }
        }
        else {
            // Otherwise, open our own snackbar created by the Hook and tell the user to answer
            snackbar({ id: "question_answer", text: props.theming.text.snack_bar_answer_needed[props.lang] });
        }

    };

    const nextButton = (
        // Custom Next button which calls our clickNext function checking whether the question is answered
        <button
            className="btn btn-secondary"
            id="nextButton"
            type="button"
            onClick={clickNext}
        >
            {/* Of course, proper translation for Button */}
            {translations[props.lang].buttons.next}
        </button>
    );

    return (

        <QuizPage
            {...props}
            nextButton={nextButton}
        >

            <main className="row">

                {/* QuestionSection. Takes up the whole width if screen is small or extra small, else half of it */}
                <div className="col-12 col-sm-12 col-md-6">

                    <div className="questionSection">

                        {/* Question Counter displaying which question we are on */}
                        <p className="question-counter">
                            {translations[props.lang].questions.counter + " " + current_question_number + "/" + all_question_amount}
                        </p>

                        <h1 className="question-text" >
                            {/* If no proper translation exists, just take the german one. */}
                            {data["text_" + props.lang] === "" ? data["text_de"] : data["text_" + props.lang]}
                        </h1>

                    </div>
                </div>

                {/* LikertQuestion. Just as Question, takes up Width when Screen is Small or ExtraSmall, else half of it */}
                <div className="col-12 col-sm-12 col-md-6 answerSection">

                    {/* Box for Answer Selection */}
                    <div className="answerBox">
                        <LikertInputProvider {...props} type="question" id={id} withText />
                    </div>

                    {/* Box for Relevance Selection */}
                    {props.config.relevance_state === "at_question" ? (
                        <div className="question-relevance">
                            <div className="justify-content-center row">

                                {/* Custom Title for Likert Scala */}
                                <p id="relevance-text">
                                    {props.theming.text.relevance_question_header[props.lang]}
                                </p>

                                <div className="relevance-likert">
                                    <LikertInputProvider {...props} type="relevance" id={id} withText allowCheckbox />
                                </div>

                            </div>
                        </div>
                    ) : <></>
                    }

                </div>

            </main>

        </QuizPage>
    );
}


export default Question;