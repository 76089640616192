import React from 'react';
import en_icon from '../images/en_flag_small.png';
import de_icon from '../images/de_flag_small.png';
import "../css/LanguageSelect.css";

const SingleFlag = (props) => {
    /*
    A Single Flag
    Is used two times in LanguageSelector, once German, once English
    */

    return (

        <button
            title={props.alt}
            className={props.lang === props.defaultLang ? "language_button_selected" : "language_button"}
            onClick={() => props.setLanguage(props.defaultLang)}
        >
            <img
                className="language_flag"
                src={props.icon}
                alt={props.alt}
                title={props.alt}
                aria-hidden="true"
            />
        </button>
    )
}


class LanguageSelector extends React.Component {
    /*
    Language Selector
    Includes two Flag Icons which set the Language to either German or English
    */

    render = () => {
        return (
            <div>
                <section title="Languages">
                    <SingleFlag {...this.props} defaultLang="en" icon={en_icon} alt="English" />

                    <SingleFlag {...this.props} defaultLang="de" icon={de_icon} alt="Deutsch" />
                </section>
            </div>
        )
    }
}

export default LanguageSelector;