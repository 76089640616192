export const Impressum = ({ marginTop, link }) => {
    marginTop = isNaN(Number.parseFloat(marginTop)) ? 0 : Number.parseFloat(marginTop);
    return (
        <div style={{ display: "flex", justifyContent: "center", marginTop: (marginTop - 2.5) + "rem", marginBottom: "1rem" }}>
            <footer title="Impressum">
                <a href={link} target="_blank" rel="noreferrer" style={{ color: "#c5c5c5", textDecorationLine: "underline" }}>
                    Impressum
                </a>
            </footer>
        </div>
    )
}